'use client';
import React from 'react';
import { Button } from '@/components/ui/button';
import Link from 'next/link';

const ArrowSVG = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.199 0.419922C17.7488 0.419922 0.355469 17.8133 0.355469 39.2635C0.355469 60.7015 17.7488 78.0948 39.199 78.0948C60.6492 78.0948 78.0425 60.7015 78.0425 39.2635C78.0425 17.8133 60.6492 0.419922 39.199 0.419922ZM55.3534 34.7451L41.0452 51.0938C40.8144 51.3611 40.5351 51.5676 40.2071 51.7133C39.8913 51.8591 39.5512 51.9319 39.199 51.9319C38.8468 51.9319 38.5067 51.8591 38.1909 51.7133C37.8629 51.5676 37.5957 51.3611 37.3649 51.0938L23.0567 34.7451C22.5587 34.2349 22.3279 33.6398 22.3522 32.9353C22.3765 32.2308 22.6559 31.6478 23.1782 31.1741C23.7126 30.7125 24.3199 30.5182 25.0244 30.5789C25.7289 30.6397 26.2997 30.9555 26.7249 31.502L39.199 45.7617L51.6731 31.502C52.1226 31.0405 52.6813 30.7976 53.3372 30.7733C53.9809 30.749 54.5518 30.9555 55.0376 31.3806C55.5356 31.8178 55.8029 32.3523 55.8636 32.996C55.9243 33.6519 55.7543 34.2349 55.3534 34.7451Z"
      fill="#5F0F90"
    />
  </svg>
);

const Card = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className={`relative flex flex-col justify-center items-center border-4 border-purple-700 bg-white px-6 rounded-[16px] w-[320px] transition-all ${
        isOpen ? 'h-[350px] py-6 overflow-auto' : 'min-h-[100px]'
      } z-10`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={`${isOpen ? 'overflow-auto' : ''}`}>
        <p
          className={`text-border ${isOpen ? 'mb-2' : 'mb-0'}`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {isOpen && children}
      </div>
      <ArrowSVG
        className={`absolute right-2 bottom-2 cursor-pointer ${
          isOpen ? 'rotate-180' : ''
        }`}
      />
    </div>
  );
};

const WhyChooseUs = () => {
  return (
    <div className="container flex flex-col items-center">
      <div className="relative mx-auto p-6">
        <h2 className="text-3xl font-[800] text-primary text-center">
          Pourquoi rejoindre notre communauté ?
        </h2>
        <div className="bg-banner min-w-[200px] max-w-[400px] rounded-full h-3 my-2 mx-auto" />
      </div>
      <div className="">
        <p className="text-primary pb-6">
          Chez Skillscal, nous comprenons que chaque étudiants est unique et
          mérite une expérience de formation personnalisée <br />
          et adapté à chacun. C&apos;est pourquoi nous avons développé des
          fonctionnalités innovantes pour vous aider à atteindre <br />
          vos objectifs et à exceller dans votre parcours éducatif.
        </p>
        <h3 className="text-primary text-left font-[800] text-xl">
          Voici quelques avantages auxquels vous aurez accès en rejoignant notre
          communauté :
        </h3>
      </div>
      <div className="flex flex-col gap-10 mt-8">
        <div className="relative flex flex-col items-center md:items-start gap-8 md:flex-row justify-start text-center">
          <Card title={'SUIVI PERSONNALISÉ ET <br /> INTERACTION DIRECTE'}>
            <p className="text-border text-justify">
              Ne vous sentez plus jamais seul dans votre apprentissage. Avec
              notre système de suivi du parcours des élèves, vous pouvez
              facilement suivre vos progrès et rester en contact direct avec vos
              formateurs grâce à notre chat intégré. Posez vos questions,
              obtenez des réponses instantanées bénéficiez et d&apos;un
              accompagnement personnalisé tout au long de votre formation.
            </p>
          </Card>
          <Card title={'SYSTÈME DE LEVELING ET RÉCOMPENSES'}>
            <p className="text-border text-justify">
              Transformez votre apprentissage en une aventure passionnante avec
              notre système de leveling. À chaque fin de cours, gagnez de
              l&apos;expérience et accédez à votre leaderboard personnel. Les
              meilleurs élèves seront récompensés avec des prix exclusifs tels
              que des skins, des formations gratuites et des points boutique.
              Devenez le meilleur et récoltez les fruits de vos efforts!
            </p>
          </Card>
          <Card title={'CV NUMÉRIQUE ET <br /> OPPORTUNITÉS DE CARRIÈRE'}>
            <p className="text-border text-justify">
              Dites adieu aux problèmes de qualification en ligne avec notre CV
              numérique. Ce puissant outil vous permet de créer un profil
              détaillé de votre carrière chez Skill, incluant des statistiques
              sur vos domaines de prédilection. Montrez vos compétences et
              choisissez parmi une vaste gamme de formations pour continuer à
              vous développer et à briller dans votre domaine.
            </p>
          </Card>
          <div className="absolute top-6 md:bottom-14 bg-banner w-full h-12 z-0 hidden md:block"></div>
        </div>
        {/* <div className="absolute mx-auto top-6 bg-banner w-[200px] h-12 z-0"></div> */}
        <div className="relative flex flex-col items-center md:items-start gap-8 md:flex-row justify-center text-center">
          <Card title={'FONCTIONNALITÉS AVANCÉES ET EXPÉRIENCE INTERACTIVE'}>
            <p className="text-border text-justify">
              Explorez les fonctionnalités avancées de notre application web,
              offrant une expérience fluide et interactive, directement depuis
              votre navigateur. Apprenez à votre rythme, où que vous soyez, sur
              votre téléphone, tablette ou ordinateur, sans installations
              supplémentaires. Profitez d&apos;un accès illimité à un large
              catalogue de cours et d&apos;un suivi personnalisé pour atteindre
              vos objectifs.
            </p>
          </Card>
          <Card title={'CERTIFICATIONS RECONNUES'}>
            <p className="text-border text-justify">
              Obtenez des compétences reconnues à la fin de chaque formation.
              Nos certifications sont conçues pour valoriser votre parcours et
              vous ouvrir nouvelles opportunités de professionnelles. Que vous
              souhaitiez améliorer vos compétences actuelles ou explorer de
              nouveaux domaines, Skill est là pour vous accompagner à chaque
              étape.
            </p>
          </Card>
          <div className="absolute top-6 md:bottom-14 bg-banner w-1/2 h-12 z-0 hidden md:block"></div>
        </div>
        {/* <div className="absolute mx-auto bottom-24 bg-banner w-[200px] h-12 z-0"></div> */}
        <h3 className="mx-auto text-primary text-center font-[800] text-xl md:py-4 md:max-w-[550px]">
          Rejoignez-nous dès aujourd&apos;hui et découvrez comment Skillscal
          peut transformer votre apprentissage en une expérience enrichissante
          et gratifiante. <br />
          Ensemble, construisons votre avenir !
        </h3>
        <Button
          variant="primary"
          className="hover:scale-110 w-52 font-sans leading-tight transition-all flex mx-auto"
          asChild
        >
          <Link
            href="https://discord.gg/pGYVrryPNT"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nous rejoindre
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default WhyChooseUs;
