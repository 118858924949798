import Image from 'next/image';
import { IoBuild } from 'react-icons/io5';
interface PhoneProps {
  imageSrc?: string;
  imageAlt?: string;
}

const Phone: React.FC<PhoneProps> = ({ imageSrc, imageAlt }) => {
  return (
    <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
      <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
      <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
      <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white select-none pointer-events-none">
        {imageSrc ? (
          <Image
            src={imageSrc}
            alt={imageAlt || 'Phone Content'}
            width={323}
            height={552}
            className="w-full h-full aspect-auto"
          />
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <IoBuild size={128} color="black" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Phone;
