'use client';
import Image from 'next/image';
import React, { useState } from 'react';
import {
  motion,
  useTransform,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from 'framer-motion';

export const AnimatedTooltip = ({
  items,
}: {
  items: {
    id: number;
    name: string;
    designation: string;
    image: string;
    role?: 'student' | 'teacher' | 'admin' | undefined;
  }[];
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const springConfig = { stiffness: 100, damping: 5 };
  const x = useMotionValue(0); // going to set this value on mouse move
  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  );
  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  );
  const handleMouseMove = (event: any) => {
    const halfWidth = event.target.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
  };

  return (
    <>
      {items.map((item, idx) => (
        <div
          className="group relative -mr-4"
          key={item.name}
          onMouseEnter={() => setHoveredIndex(item.id)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <AnimatePresence mode="popLayout">
            {hoveredIndex === item.id && (
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.6 }}
                animate={{
                  opacity: 1,
                  y: -20,
                  scale: 1,
                  transition: {
                    type: 'spring',
                    stiffness: 260,
                    damping: 10,
                  },
                }}
                exit={{ opacity: 0, y: 20, scale: 0.6 }}
                style={{
                  translateX: translateX,
                  rotate: rotate,
                  whiteSpace: 'nowrap',
                }}
                className="absolute -left-24 bottom-full mb-0.5 z-40 w-64 flex translate-x-1/2 flex-col items-center justify-start rounded-md bg-background px-4 py-2 text-xs shadow-lg"
              >
                {/* Staffs */}
                {item?.role === "admin" && (
                  <Image width={38} height={33} src={"/simpleLogo.svg"} alt='staff' className="absolute -top-6 bg-primary-foreground rounded-full p-1 transition duration-300 z-30" loading='lazy' draggable={false} />
                )}

                {/* Formateurs */}
                {item?.role === "teacher" && (
                  <Image width={48} height={36} src={"/img/joinUs/hat-staff.webp"} alt='formateur' className="absolute -top-4 transition duration-300 z-30 max-w-[40px]" loading='lazy' draggable={false} />
                )}
                
                {/* Eleves */}
                {item?.role === "student" && (
                  <Image width={48} height={12} src={"/img/joinUs/diploma.webp"} alt='eleve' className="absolute -top-2 rotate-45 transition duration-300 z-30 max-w-[40px]" loading='lazy' draggable={false}/>            
                )}
                <div className="absolute right-24 -bottom-px z-30 h-px w-1/5 bg-gradient-to-r from-transparent via-fuchsia-500 to-transparent " />
                <div className="absolute -bottom-px right-24 z-30 h-px w-2/5 bg-gradient-to-r from-transparent via-purple-500 to-transparent " />
                <div className="relative z-30 text-base font-bold text-foreground">
                  {item.name}
                </div>
                <p className="text-xs text-center text-foreground w-64 text-wrap px-4 leading-3">
                  {item.id === 99 ? "Autres membres" : item.designation}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <Image
            onMouseMove={handleMouseMove}
            height={100}
            width={100}
            src={item.image}
            alt={item.name}
            className="relative !m-0 size-14 rounded-full border-2 border-primary object-cover object-top !p-0 transition duration-500 group-hover:z-30 group-hover:scale-105"
            loading="lazy"
            draggable={false}
          />
          {item.id === 99 && (
            <div className="absolute top-0 flex items-center justify-center rounded-full w-full h-full bg-primary/40 text-primary-foreground text-center font-bold z-20 group-hover:z-30">{item.name}</div>
          )}
        </div>
      ))}
    </>
  );
};