'use client';
import React, { use } from 'react';
import { motion } from 'framer-motion';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { AnimatedTooltip } from '@/components/ui/animated-tooltip';
import { Member } from '@/types/DiscordTypes';
import { orderRole, getRole } from '@/lib/discord';
import Link from 'next/link';

interface People {
  id: number;
  name: string;
  designation: string;
  image: string;
  isStaff: boolean;
}

const FAQ = () => {
  const [people, setPeople] = React.useState<People[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const faqs = [
    {
      question: "Qu'est-ce que Skillscal ?",
      answer:
        'Skillscal est une plateforme de formation en ligne qui permet aux experts de partager leurs connaissances avec des étudiants du monde entier.',
    },
    {
      question: 'Comment puis-je devenir formateur ?',
      answer:
        'Vous pouvez devenir formateur en soumettant votre candidature via notre formulaire en ligne. Nous évaluerons votre demande et vous contacterons pour un entretien.',
    },
    {
      question: 'Quels sont les avantages de devenir formateur ?',
      answer:
        'Devenir formateur sur Skillscal vous offre une visibilité accrue, une rémunération attractive, et la possibilité de faire une différence dans la vie des étudiants.',
    },
    {
      question: 'Quels types de cours puis-je proposer ?',
      answer:
        "Vous pouvez proposer une variété de cours, tant qu'ils apportent une valeur éducative significative aux étudiants. Nous acceptons des cours dans divers domaines.",
    },
    {
      question: 'Comment sont rémunérés les formateurs ?',
      answer:
        "Les formateurs sont rémunérés sur la base du nombre d'étudiants inscrits à leurs cours et des évaluations de ceux-ci. Nous offrons une structure de rémunération compétitive.",
    },
    {
      question: 'Comment puis-je contacter le support ?',
      answer:
        'Vous pouvez contacter notre support via notre site web ou rejoindre notre serveur Discord pour obtenir des réponses à toutes vos questions.',
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/discord/members?params=staffs');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        if (result.length) {
          setPeople(
            result.map(
              (person: {
                id: string;
                name: string;
                designation: string;
                image: string;
                roles: string[];
              }) => ({
                id: person.id === 'remaining' ? 99 : parseInt(person.id),
                name: person.name,
                designation: orderRole(person.roles),
                image: person.image,
                role: getRole(person.roles),
              })
            )
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section
      id="faq"
      className="container relative mx-auto bg-background text-foreground p-8"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 md:mx-32 gap-12">
        <div className="flex flex-col justify-between">
          <div className="contents">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-primary mb-4">
                Vous souhaitez en savoir plus ?
              </h2>
              <div className="bg-banner w-full sm:w-[360px] rounded-full h-3 my-2 mb-8 mx-auto" />
              <p className="text-xl text-primary md:mx-20">
                Rejoignez-nous sur Discord,
                <br /> nous répondrons à toutes vos questions.
              </p>
            </div>
            <div className="relative flex flex-row items-center justify-center mb-10">
              <AnimatedTooltip items={people} />
            </div>
            <div className="flex justify-center">
              <Button
                variant={'primary'}
                className="shadow-xl text-xl hover:scale-110 transition-all w-1/2"
                asChild
              >
                <Link
                  href="https://discord.gg/pGYVrryPNT"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nous rejoindre
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <Accordion type="multiple">
            {faqs.map((faq, index) => (
              <AccordionItem key={index} value={index.toString()}>
                <AccordionTrigger className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">{faq.question}</h3>
                </AccordionTrigger>
                <AccordionContent className="mt-4 text-muted-foreground">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
