import React, { useEffect } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import Phone from '../../ui/phone';

import styles from './cardStack.module.css';
import Loading from '../../ui/loading';
import { useTheme } from 'next-themes';

interface DeckProps {
  position: 'absolute' | 'static';
}

const cards = [
  '/img/previews/dashboard.webp',
  '/img/previews/settings-profile.webp',
  '/img/previews/settings-appearance.webp',
  '/img/previews/create-cours.webp',
];
const cardsLight = [
  '/img/previews/dashboard_light.webp',
  '/img/previews/settings-profile_light.webp',
  '/img/previews/settings-appearance_light.webp',
  '/img/previews/create-cours_light.webp',
];

const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (_i: number) => ({
  x: 0,
  rot: Math.random() * -20,
  scale: 1,
  y: 0,
});

const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

function Deck({ position }: DeckProps) {
  const [isMounted, setIsMounted] = React.useState(false);
  const [innerWidth, setInnerWidth] = React.useState(800); // Valeur par défaut pour le SSR
  const [gone] = React.useState(() => new Set()); // The set flags all the cards that are flicked out
  const { theme, systemTheme } = useTheme();

  const currentTheme = !theme ? systemTheme : theme;

  useEffect(() => {
    setIsMounted(true);
    setInnerWidth(window.innerWidth);

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({
      args: [index],
      active,
      movement: [mx],
      direction: [xDir],
      velocity: [vx],
    }) => {
      const trigger = vx > 0.2; // If you flick hard enough it should trigger the card to fly out
      if (!active && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + innerWidth) * xDir : active ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = active ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!active && gone.size === cards.length)
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
    }
  );

  if (!isMounted) {
    return <Loading />;
  }
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div
          className={`${styles.deck} ${styles[position]}`}
          key={i}
          style={{ x, y }}
        >
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
            }}
          >
            {/* Intégration de Phone avec la prop style pour les transformations */}
            <Phone
              imageSrc={currentTheme === 'dark' ? cardsLight[i] : cards[i]}
            />
          </animated.div>
        </animated.div>
      ))}
    </>
  );
}

export default Deck;
