import React from 'react';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { CardType } from '@/types/CardTypes';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

const CardReviews = ({ text }: { text: CardType }) => {
  return (
    <Card className="relative flex w-full flex-col justify-between rounded-[32px] shadow-lg border-border border-4">
      <QuoteSVG className="absolute -top-6 -left-[30px]" />
      <CardHeader>
        <CardTitle>{text.title}</CardTitle>
      </CardHeader>

      <CardContent>
        <ScrollArea className="w-full h-[250px]">
          <p className="text-justify text-foreground w-[94%] mr-auto tracking-normal">
            {text?.descriptions}
          </p>
        </ScrollArea>
      </CardContent>

      <CardFooter>
        <div className="mt-auto">
          <div className="flex items-center pt-4 pb-2">
            {text &&
              text.rating !== null &&
              Array.from({ length: 5 }).map((_, i) => (
                <StarSVG key={i} filled={text.rating >= i + 1} />
              ))}
          </div>
          <div className="flex flex-row items-center gap-3">
            <Avatar className="border-2 border-primary">
              <AvatarImage
                src={text?.avatar}
                alt={text?.author}
                width={36}
                height={36}
                loading="lazy"
              />
              <AvatarFallback className="text-primary text-2xl font-bold select-none">
                {' '}
                {text?.author.charAt(0).toUpperCase()}{' '}
              </AvatarFallback>
              <p className="sr-only"> {text?.author} </p>
            </Avatar>
            <div className="flex flex-col items-start">
              <p>{text?.author}</p>
              <p className="text-sm line-clamp-1">{text?.role}</p>
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

const StarSVG = ({ filled }: { filled: boolean }) => (
  <svg
    width="32"
    height="30"
    viewBox="0 0 79 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6862 0.77832L49.7676 22.933C50.1563 23.7832 50.7757 24.512 51.5409 25.0586C52.3183 25.6051 53.2171 25.9452 54.1645 26.0424L78.8456 28.5688L60.3955 44.784C59.691 45.4034 59.1566 46.1929 58.8651 47.0796C58.5736 47.9663 58.525 48.9137 58.7193 49.8247L63.8815 73.534L42.407 61.4C41.5811 60.9384 40.6458 60.6955 39.6862 60.6955C38.7388 60.6955 37.8036 60.9384 36.9776 61.4L15.491 73.534L20.6653 49.8247C20.8596 48.9137 20.8111 47.9663 20.5196 47.0796C20.2159 46.1929 19.6936 45.4034 18.9891 44.784L0.539062 28.5688L25.2201 26.0424C26.1675 25.9452 27.0664 25.6051 27.8437 25.0586C28.6089 24.512 29.2162 23.7832 29.6049 22.933L39.6862 0.77832Z"
      fill={filled ? '#F4C01E' : ''}
      stroke={filled ? '' : '#F4C01E'}
      strokeWidth="4"
    />
  </svg>
);

const QuoteSVG = ({ className }: { className?: string }) => (
  <svg
    width="60"
    height="42"
    viewBox="0 0 60 42"
    fill="none"
    className={` ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7662 9.58394C22.7843 9.66805 20.4141 10.1459 18.648 11.0252C16.8818 11.9083 15.4903 13.2883 14.4696 15.173C18.5486 15.173 21.9318 16.1708 24.6193 18.1701C27.303 20.1695 28.6486 23.4266 28.6486 27.9337C28.6486 29.6196 28.3543 31.2673 27.7655 32.8767C27.1768 34.4823 26.2746 35.9044 25.0589 37.1392C23.8433 38.374 22.3715 39.3947 20.6473 40.2013C18.9232 41.0041 16.943 41.4055 14.7066 41.4055C10.2759 41.4055 6.71679 39.9643 4.03313 37.0819C1.34565 34.2032 0 30.3498 0 25.5291C0 22.8608 0.38611 20.1657 1.15068 17.4399C1.91144 14.7181 3.2762 12.1873 5.23734 9.85154C7.19847 7.51959 9.8439 5.47053 13.1774 3.70819C16.511 1.94202 20.7085 0.703409 25.7662 0V9.58394ZM57.1176 9.58394C54.1357 9.66805 51.7655 10.1459 49.9994 11.0252C48.237 11.9083 46.8226 13.2883 45.7636 15.1768C49.8809 15.1768 53.2832 16.1746 55.9707 18.1739C58.6543 20.1733 60 23.4266 60 27.9376C60 29.6234 59.698 31.2711 59.0902 32.8805C58.4785 34.4861 57.5763 35.9082 56.3797 37.143C55.187 38.3778 53.7267 39.3985 51.9987 40.2013C50.2708 41.008 48.2752 41.4055 46.0006 41.4055C41.6082 41.4055 38.0605 39.9643 35.3539 37.0857C32.6473 34.2032 31.294 30.3498 31.294 25.5253C31.294 22.8608 31.6763 20.1618 32.4409 17.4361C33.2055 14.7104 34.5779 12.1835 36.5581 9.85154C38.5346 7.51577 41.1953 5.46671 44.5288 3.70436C47.8624 1.94202 52.0561 0.703409 57.1176 0V9.58394Z"
      fill="#5F0F90"
    />
  </svg>
);

export default CardReviews;
