'use client';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Badge } from '@/components/ui/badge';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
  slideInFromTop,
} from '@/utils/motion';
import { Button } from '../../ui/button';
import { useNav } from '@/context/NavContext';
import { linkClick } from '@/components/navbar/Navbar';

export const Header = () => {
  const { ref, inView } = useInView();
  const { activeSection, updateActiveSection } = useNav();
  const handler = (e: React.MouseEvent<HTMLAnchorElement>, section: string) => {
    updateActiveSection(section);
    linkClick(e, section);
  }
  return (
    <div
      id="home"
      className="mx-6 pt-20 lg:container lg:mx-auto xl:mx-auto flex flex-col lg:flex-row gap-8 justify-between items-center text-center md:text-start"
    >
      <div className="flex justify-center w-full">
        <Image
          src="/img/header/header_illustration.webp"
          alt="header_illustration"
          width={640}
          height={397}
          priority
          className="select-none pointer-events-none max-w-[440px] md:max-w-[640px] w-auto h-auto"
          draggable={false}
        />

        <Image
          src="/img/header/header_bot.webp"
          alt="header_bot"
          priority
          className="absolute top-[80px] md:top-[150px] animate-float select-none pointer-events-none"
          width={600}
          height={408}
          draggable={false}
        />
      </div>

      <motion.div className="flex h-full flex-col gap-8 justify-center w-full">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={slideInFromTop()}
        >
          <h1 className="text-2xl md:text-3xl font-semibold text-primary uppercase leading-snug md:max-w-[550px]">
            Rejoingnez une communauté d&apos;étudiants ambitieux et développez
            vos compétences au niveau supérieur
          </h1>

          <div className="flex flex-col gap-4 mt-3">
            <p className="tracking-wider text-lg text-primary/80 leading-relaxed md:max-w-[550px]">
              Avec Skillscal, la plateforme de prédilection pour
              l&apos;apprentissage et l&apos;enseignement au cœur de
              l&apos;innovation et de l&apos;interaction.
            </p>
            <div className="w-full flex flex-col-reverse md:flex-row gap-5 md:gap-2 justify-center md:justify-start items-center mt-6 md:mt-2">
              <div className="w-full flex flex-col gap-8 justify-center md:mr-20">
                <Button
                  variant={'primary'}
                  className="shadow-xl text-xl hover:scale-110 transition-all"
                >
                  <Link
                    href="#about"
                    onClick={(e) => handler(e, 'about')}
                  >
                    Je souhaite me former
                  </Link>
                </Button>
                <Button
                  variant={'primaryOutline'}
                  className="shadow-xl text-xl hover:scale-110 transition-all"
                >
                  <Link
                    href="#become-trainer"
                    onClick={(e) => handler(e, 'become-trainer')}
                  >
                    Je souhaite enseigner
                  </Link>
                </Button>
              </div>
              <div className='relative w-full max-w-[180px]'>
                <Image
                  src="/img/header/header_books.webp"
                  alt="logo"
                  width={256}
                  height={254}
                  priority
                  className="select-none pointer-events-none"
                  draggable={false}
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={slideInFromLeft()}
          className="flex gap-8 justify-center md:justify-start items-center pt-6"
        >
          <Link href="" className="relative">
            <Image
              src="/app-google-badge.svg"
              alt="app-google-badge"
              width={150}
              height={47}
              priority
              className="shadow-xl transition w-[150px] min-h-[43.5px] h-[43.5px] object-cover rounded-[8px] brightness-50"
            />
            <InDevelopment />
          </Link>
          <Link href="" className="relative">
            <Image
              src="/app-store-badge.svg"
              alt="app-store-badge"
              width={150}
              height={47}
              priority
              className="shadow-xl transition w-[150px] min-h-[47px] h-[47px] object-cover rounded-[8px] brightness-50"
            />
            <InDevelopment />
          </Link>
        </motion.div> */}
      </motion.div>
    </div>
  );
};

export default Header;
