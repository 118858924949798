'use client';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import * as z from 'zod';
import { useToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormControl,
} from '@/components/ui/form';

const subscriptionSchema = z.object({
  email: z
    .string()
    .email('Invalid email')
    .min(1, { message: 'Email is required' }),
});

const NewsLetter = () => {
  const { toast } = useToast();
  const router = useRouter();
  const form = useForm({
    resolver: zodResolver(subscriptionSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof subscriptionSchema>) => {
    const isValid = await form.trigger();
    if (!isValid) return;

    form.reset();
    const response = await fetch('/api/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    });
    const result = await response.json();

    if (response.ok) {
      toast({
        title: 'Abonnement réussi',
        description: 'Vous êtes maintenant abonné à notre newsletter.',
      });
      router.push('/');
    } else {
      toast({
        title: "Échec de l'abonnement",
        description: result.error,
      });
    }
  };

  return (
    <Form {...form}>
      <div id="newsletter" className="container py-10">
        <div className="flex flex-col gap-8 lg:flex-row items-center justify-center lg:justify-between">
          <div className="flex flex-col items-center">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Image
                src="/img/newsLetter/Tel.webp"
                alt="News Letter Phone Image"
                loading="lazy"
                width={250}
                height={250}
                className="w-40 h-40 md:w-60 md:h-60"
                draggable={false}
              />
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-2"
              >
                <h2 className="text-2xl lg:text-4xl font-bold text-primary">
                  Pour ne rien manquer
                </h2>
                <p className="text-sm lg:text-md">
                  Recevez chaque semaine les dernières actualités!
                </p>
                <div className="relative flex items-center space-x-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="relative">
                        <FormControl>
                          <Input
                            type="email"
                            placeholder="Entrez votre email"
                            className="w-full bg-white text-black rounded-full border-border border-4 placeholder:text- pr-32"
                            {...field}
                          />
                        </FormControl>
                        <Button
                          variant="primary"
                          disabled={!form.formState.isValid}
                          className="absolute top-0 right-2 h-6 rounded-full text-white whitespace-nowrap"
                        >
                          Je m&apos;abonne
                        </Button>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="max-w-[300px] max-h-[300px]">
            <Image
              src="/logo.webp"
              alt="logo"
              width={384}
              height={109}
              className="block dark:hidden"
              loading="lazy"
              draggable="false"
            />
            <Image
              src="/logo_white.webp"
              alt="logo"
              width={384}
              height={109}
              className="hidden dark:block"
              loading="lazy"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default NewsLetter;
