import Image from 'next/image';
import React from 'react';
import { Event, Section } from './LastNews';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { RiDiscordFill, RiDiscordLine } from 'react-icons/ri';
import { AnimatedTooltip } from '@/components/ui/animated-tooltip';
import Link from 'next/link';

const CardEvent = ({ event, index }: { event: Event; index: number }) => {
  return (
    <div
      key={index}
      className="relative w-full h-full flex flex-col max-w-xs min-w-xs border-4 border-border rounded-xl px-3 py-4 md:mx-6 bg-background"
    >
      <div className="absolute -top-[1px] -left-[1px] flex items-center bg-border text-primary-foreground rounded-br-lg rounded-tl-lg w-auto h-5 px-4 select-none">
        EVENT
      </div>
      <h3 className="text-primary text-2xl font-bold mb-4 mt-4 truncate line-clamp-1 text-wrap">
        {event.name}
      </h3>
      <p className="text-primary mb-4 truncate line-clamp-2 text-wrap">
        {event.description}
      </p>
      <ScrollArea className="h-[9.5rem] mb-4 bg-[#5F0F90]/[0.15] rounded-lg px-4 py-1">
        <h4 className="text-primary font-bold mb-2">Prochaines sessions :</h4>
        <ul className="space-y-2 md:list-disc md:pl-6 leading-1">
          {event.occurrences.map((occurrence, idx) => (
            <li key={idx} className="text-primary">
              {occurrence.formattedDate}
            </li>
          ))}
        </ul>
      </ScrollArea>
      <div className="relative flex items-center space-x-4 px-1 mb-5">
        <div className="relative mr-2 group">
          <AnimatedTooltip
            items={[
              {
                id: 1,
                name: event.creator.global_name,
                designation: event.creator.role,
                image: `https://cdn.discordapp.com/avatars/${event.creator.id}/${event.creator.avatar}.png`,
              },
            ]}
          />
          <RiDiscordFill
            size={28}
            className="absolute -bottom-1 -right-5 text-[#5865F2] bg-primary-foreground/90 rounded-full p-1 z-[31] transition-all duration-600 group-hover:scale-125 "
            title={event.creator.global_name + ' sur Discord'}
          />
        </div>
        <p className="text-primary font-bold truncate line-clamp-1 text-wrap">
          Animé par {event.creator.global_name}
          <span className="text-primary/80 truncate line-clamp-1 text-wrap font-normal">
            {event.channel_name}
          </span>
        </p>
      </div>
      <Button
        variant="primary"
        className="rounded-full w-full shadow-xl text-md whitespace-nowrap"
        asChild
      >
        <Link
          href="https://discord.gg/pGYVrryPNT"
          target="_blank"
          rel="noopener noreferrer"
        >
          S&apos;inscrire à l&apos;événement
        </Link>
      </Button>
    </div>
  );
};

export default CardEvent;
