import Image from 'next/image';
import React from 'react';
import { MediumPost } from './LastNews';
import { Button } from '@/components/ui/button';
import Link from 'next/link';

const stripHTML = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  let content = '';
  paragraphs.forEach((p) => {
    content += p.textContent + ' ';
  });
  return content.trim();
};

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const CardMedium = ({ post, index }: { post: MediumPost; index: number }) => {
  const cleanContent = stripHTML(post.content);
  const truncatedContent = truncateText(cleanContent, 100);

  return (
    <div
      key={index}
      className="relative w-full max-w-xs h-full min-w-xs border-4 border-border rounded-xl pt-8 pb-4 px-6 md:mx-6 bg-background"
    >
      <div className="absolute -top-[1px] -left-[1px] flex items-center bg-border text-primary-foreground rounded-br-lg rounded-tl-lg w-auto h-5 px-4 select-none">
        MÉDIUM
      </div>
      <div className="relative h-60">
        <div className="absolute inset-0 bg-border opacity-50 rounded-lg" />
        <Image
          src={post.image}
          alt="default medium post"
          className="rounded-lg size-full object-cover"
          width={500}
          height={500}
          loading="lazy"
        />
      </div>
      <div className="py-4 text-primary space-y-2">
        <h4 className="font-bold truncate line-clamp-1 text-wrap">
          {post.title}
        </h4>
        <p className="truncate line-clamp-2 text-wrap min-h-12">
          {truncatedContent}
        </p>
      </div>
      <Button
        variant="primary"
        className="rounded-full w-full shadow-xl text-md whitespace-nowrap"
        asChild
      >
        <Link href={post.link} target="blank" rel="noopener noreferrer">
          Lire l&apos;article
        </Link>
      </Button>
    </div>
  );
};

export default CardMedium;
