'use client';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { slideInFromRight } from '@/utils/motion';
import { AnimatedTooltip } from '@/components/ui/animated-tooltip';
import { orderRole, getRole } from '@/lib/discord';
import DiscordIframe from './DiscordIframe';

interface People {
  id: number;
  name: string;
  designation: string;
  image: string;
  role?: 'student' | 'teacher' | 'admin' | undefined;
  status?: 'online' | 'idle' | 'dnd' | 'offline' | undefined;
}

const JoinUs: React.FC = () => {
  const { ref, inView } = useInView();
  const [people, setPeople] = useState<People[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/discord/members?params=all');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        if (result.length) {
          setPeople(
            result.map(
              (person: {
                id: string;
                name: string;
                designation: string;
                image: string;
                roles: string[];
              }) => ({
                id: person.id === 'remaining' ? 99 : parseInt(person.id),
                name: person.name,
                designation: orderRole(person.roles),
                image: person.image,
                role: getRole(person.roles),
              })
            )
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
      <motion.section
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={slideInFromRight()}
        className="relative container pb-10"
        id="join-us"
      >
        <div className="flex flex-col items-center text-center">
          <h2 className="text-3xl font-bold text-primary">
            Rejoignez notre communauté sur Discord
          </h2>
          <div className="bg-banner w-full sm:w-[470px] rounded-full h-3 my-2 mb-8" />
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-center items-center text-center md:text-left">
          <div className="flex flex-col items-center max-w-xl text-primary">
            <p className="text-xl">
              Rejoignez-nous dès aujourd&apos;hui et embarquez dans une aventure
              éducative qui transformera votre vision de l&apos;apprentissage et
              qui vous ouvrira de nouvelles opportunités professionnelles et
              personnelles. Que vous cherchiez à développer de nouvelles
              compétences ou à approfondir vos connaissances dans un domaine
              spécifique, nous sommes là pour vous aider à réussir.
            </p>
            <p className="text-2xl font-bold mt-8">
              N&apos;attendez plus pour vous former et Rejoignez-nous
              gratuitement sur Discord
            </p>
            <div className="relative flex flex-row items-center justify-center mt-10">
              <AnimatedTooltip items={people} />
            </div>
          </div>
          <DiscordIframe />
        </div>
      </motion.section>
  );
};

export default JoinUs;
