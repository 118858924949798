'use client';

import { Button } from '@/components/ui/button';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import Link from 'next/link';

const WhatIsSS = () => {
  const [totalMembers, setTotalMembers] = useState<number | null>(null);
  const [totalFormateurs, setTotalFormateurs] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchTotalMembers = async () => {
    const response = await fetch('/api/discord/memberCount');
    const data = await response.json();
    return data.totalMembers;
  };

  const fetchTotalFormateurs = async () => {
    const response = await fetch('/api/discord/formateurCount');
    const data = await response.json();
    return data.totalFormateurs;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [members, formateurs] = await Promise.all([
          fetchTotalMembers(),
          fetchTotalFormateurs(),
        ]);
        setTotalMembers(members);
        setTotalFormateurs(formateurs);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="about" className="flex flex-col items-center pb-10 pt-2">
      <div className="flex gap-1 items-center justify-center mb-1">
        <h2 className="text-2xl text-primary font-bold">QU’EST CE QUE</h2>
        <Image
          src="/logo.webp"
          alt="logo"
          width={150}
          height={43}
          className="block dark:hidden"
        />
        <Image
          src="/logo_white.webp"
          alt="logo"
          width={150}
          height={43}
          className="hidden dark:block"
        />
      </div>
      <div className="bg-banner min-w-[300px] rounded-full h-3 mb-10"></div>
      <div className="container text-lg mb-10 max-w-3xl space-y-3 text-primary text-justify px-8 md:px-1">
        <p>
          <b>Bien plus qu&apos;une simple plateforme :</b> Nous sommes une
          communauté et notre mission est de démocratiser l&apos;accès à des
          connaissances de pointe dans différentes domaines qui ont de l’avenir.
        </p>
        <p>
          Nous sélectionnons rigoureusement les formations les plus pertinentes
          et les plus innovantes du marché pour vous garantir une expérience
          d&apos;apprentissage sans égale.
        </p>
        <p>
          Nous croyons en une approche pratique et interactive de
          l&apos;enseignement, où chaque élève est acteur de son parcours
          éducatif. Que vous soyez débutant assoiffé de connaissances ou expert
          désireux de partager votre savoir.
        </p>
      </div>
      <h3 className="text-xl text-primary mb-6">Quelques chiffres :</h3>
      <div className="relative w-full">
        <div className="flex flex-col md:flex-row justify-center md:space-x-8 mb-10 text-center mx-2">
          <div className="flex justify-center space-x-4 md:space-x-8 mb-4 md:mb-0">
            <div className="border-2 border-purple-700 bg-background px-6 py-8 rounded-[16px] w-48 z-10">
              <p className="text-primary font-semibold">
                + 600 Followers sur Twitter
              </p>
            </div>
            <div className="border-2 border-purple-700 bg-background px-6 py-8 rounded-[16px] w-48 z-10">
              {loading ? (
                <Skeleton className="size-full rounded-full" />
              ) : (
                <p className="text-primary font-semibold">
                  + de {totalMembers} Étudiants sur Discord
                </p>
              )}
            </div>
          </div>
          <div className="border-2 border-purple-700 bg-background px-6 py-8 rounded-[16px] w-48 z-10 mx-auto md:mx-0">
            {loading ? (
              <Skeleton className="size-full rounded-full" />
            ) : (
              <p className="text-primary font-semibold">
                + de {totalFormateurs} Formateurs disponibles
              </p>
            )}
          </div>
        </div>
        <div className="absolute top-9 bg-banner w-full h-12 z-0"></div>
      </div>
      <Button
        variant="primary"
        className="hover:scale-110 w-52 font-sans leading-tight transition-all"
        asChild
      >
        <Link
          href="https://discord.gg/pGYVrryPNT"
          target="_blank"
          rel="noopener noreferrer"
        >
          Nous rejoindre
        </Link>
      </Button>
    </div>
  );
};

export default WhatIsSS;
