import { useCookieConsent } from '@/context/CookieConsentContext';
import Image from 'next/image';

const DiscordIframe = () => {
  const { consentGiven } = useCookieConsent();

  if (!consentGiven) {
    return (
      <div className="flex justify-center">
        <Image
          src="/simpleLogo.svg"
          alt="Logo"
          width={150}
          height={150}
          className="text-primary animate-bounce transition-all block dark:hidden"
        />
        <Image
          src="/simpleLogoWhite.svg"
          alt="Logo"
          width={150}
          height={150}
          className="text-primary animate-bounce transition-all dark:block hidden"
        />
      </div>
    );
  }

  return (
    <div className="flex justify-center pl-0 md:pl-20">
      <iframe
        src="https://discord.com/widget?id=1080524190232346735&theme=dark"
        width="300"
        height="400"
        title="Discord server"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        className="rounded-xl"
        suppressHydrationWarning
      />
    </div>
  );
};

export default DiscordIframe;
