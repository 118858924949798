'use client';
import React, { useState } from 'react';
import Deck from './Deck';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

interface StackSwitchProps {}

const StackSwitch: React.FC<StackSwitchProps> = () => {
  const [isAbsolute, setIsAbsolute] = useState(true);

  const toggleMode = () => {
    setIsAbsolute((prevState) => !prevState);
  };

  // Définir le texte du label en fonction du mode
  const label = isAbsolute ? 'Stack' : 'Unstack';

  return (
    <div className="pb-16">
      <div className="relative flex justify-center h-[530px]">
        <Deck position={isAbsolute ? 'absolute' : 'static'} />
      </div>
      {/* <div className="flex justify-center items-center space-x-2 py-4">
        <Switch id="stack-mode" onClick={toggleMode} title='switch' />
        <Label htmlFor="stack-mode">{label}</Label>
      </div> */}
    </div>
  );
};

export default StackSwitch;
