'use client';
import React from 'react';
import StackSwitch from './StackSwitch';
import Link from 'next/link';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
  slideInFromTop,
} from '@/utils/motion';
import { Button } from '../../ui/button';
import { usePWA } from '@/context/PWAContext';
import InDevelopment from '../../ui/in-development';

const QuoteSVG = ({ className }: { className?: string }) => (
  <svg
    className={`inline-block align-baseline px-1 ${className}`}
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0392 0.780762V2.04397C8.21728 2.72415 6.75974 3.8416 5.66659 5.39632C4.56128 6.92674 4.0147 8.40857 4.0147 9.86612C4.0147 10.1576 4.07543 10.4248 4.20904 10.6435C4.3305 10.8743 4.45197 10.9836 4.54914 10.9836C4.60987 10.9836 4.85279 10.8257 5.27791 10.4977C5.72732 10.1819 6.34677 10.0119 7.12413 10.0119C8.19299 10.0119 9.09181 10.4491 9.84488 11.3237C10.6222 12.1982 11.0109 13.2428 11.0109 14.4331C11.0109 15.7327 10.5372 16.8502 9.60195 17.7854C8.69099 18.7328 7.58569 19.1944 6.29819 19.1944C4.74347 19.1944 3.40739 18.5628 2.31424 17.2996C1.24537 16.0121 0.710938 14.3724 0.710938 12.3925C0.710938 9.70822 1.50044 7.364 3.09159 5.34773C4.70704 3.30717 7.02696 1.7889 10.0392 0.780762ZM23.1571 0.780762V2.04397C21.3109 2.72415 19.8291 3.8416 18.7359 5.39632C17.6306 6.92674 17.084 8.40857 17.084 9.86612C17.084 10.1576 17.1447 10.4248 17.2783 10.6435C17.3998 10.8743 17.5334 10.9836 17.667 10.9836C17.6913 10.9836 17.9342 10.8257 18.3958 10.4977C18.8452 10.1819 19.4647 10.0119 20.242 10.0119C21.2744 10.0119 22.1611 10.4491 22.9142 11.3237C23.6915 12.1982 24.0802 13.2428 24.0802 14.4331C24.0802 15.7327 23.6187 16.8502 22.7198 17.7854C21.8089 18.7328 20.6914 19.1944 19.3675 19.1944C17.8128 19.1944 16.4767 18.5628 15.3835 17.2996C14.3147 16.0121 13.7802 14.3724 13.7802 12.3925C13.7802 9.70822 14.5697 7.364 16.1609 5.34773C17.7763 3.30717 20.1084 1.7889 23.1571 0.780762Z" />
  </svg>
);

const CardStack: React.FC = () => {
  const { ref, inView } = useInView();
  const { handleInstallClick, isInstallable } = usePWA();
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={slideInFromLeft()}
      id="downloadApp"
      className="container pt-10 mt-4"
    >
      <div className="flex flex-col md:flex-row-reverse gap-2 items-center justify-center text-center md:text-start">
        <div className="w-full md:max-w-xl">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-3xl font-[800] text-primary">
              Découvrez Notre Web App
            </h2>
            <div className="bg-banner w-full max-w-[320px] rounded-full h-3 my-2 mb-8" />
          </div>
          <p className='inline nowrap text-justify font-["Lora-Regular"] text-primary font-[800] my-8 text-lg'>
            <QuoteSVG className="fill-primary" />
            Une plateforme d&apos;apprentissage interactif et de soutien
            collaboratif facilitant le développement de chacun.
            <QuoteSVG className="rotate-180 fill-primary" />
          </p>
          <p className="text-justify text-primary my-8">
            Explorez les fonctionnalités avancées de notre application web,
            offrant une expérience fluide et interactive, directement depuis
            votre navigateur.
            <br />
            <br /> Apprenez à votre rythme, où que vous soyez, sur votre
            téléphone, tablette ou ordinateur, sans installations
            supplémentaires.
          </p>
          <Image
            alt="arrow"
            src={'/img/whyChooseUs/arrow.webp'}
            width={140}
            height={141}
            loading="lazy"
            className="md:rotate-0 -rotate-90 md:mx-0 ml-auto"
          />
        </div>
        <div className="w-full">
          <StackSwitch />
          {/* <div className="flex gap-8 justify-center items-center mx-auto">
            {isInstallable && (
              <Button
                variant="link"
                className="min-h-[47px] h-[47px] object-cover rounded-[8px]"
                onClick={handleInstallClick}
              >
                Télécharger l&apos;application
              </Button>
            )}
            {/* <Link href="">
              <Image
                src="/app-google-badge.svg"
                alt="app-google-badge"
                width={150}
                height={47}
                className="hover:scale-110 shadow-xl transition w-[150px] min-h-[47px] h-[47px] object-cover rounded-[8px]"
              ></Image>
            </Link>
            <Link href="">
              <Image
                src="/app-store-badge.svg"
                alt="app-store-badge"
                width={150}
                height={47}
                className="hover:scale-110 shadow-xl transition w-[150px] min-h-[47px] h-[47px] object-cover rounded-[8px]"
              ></Image>
            </Link>
          </div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default CardStack;
