
const DISCORD_SERVER_ID = process.env.NEXT_PUBLIC_DISCORD_SERVER_ID;
const DISCORD_BOT_TOKEN = process.env.DISCORD_BOT_TOKEN;

// Type pour les informations de serveur
export interface DiscordServerInfo {
    id: string;
    name: string;
    description: string;
    member_count: number;
    icon: string;
    // Ajoutez d'autres champs pertinents ici
}

// Type pour les canaux
export interface DiscordChannel {
    id: string;
    name: string;
    type: number;
    topic: string;
    // Ajoutez d'autres champs pertinents ici
}

// Type pour les membres
export type DiscordMember = {
    id: string;
    avatar: string;
    user: {
        id: string;
        username: string;
        discriminator: string;
        global_name: string;
    }
    roles: string[];
    joined_at: string;
};

// Type pour les rôles
export interface DiscordRole {
    id: string;
    name: string;
    color: number;
    hoist: boolean;
    position: number;
    permissions: string;
    managed: boolean;
    mentionable: boolean;
    // Ajoutez d'autres champs pertinents ici
}

// Type pour les emojis
export interface DiscordEmoji {
    id: string;
    name: string;
    roles: string[];
    user: {
        id: string;
        username: string;
        avatar: string;
    };
    require_colons: boolean;
    managed: boolean;
    animated: boolean;
    available: boolean;
    // Ajoutez d'autres champs pertinents ici
}

export async function fetchDiscordServerInfo(): Promise<DiscordServerInfo> {
    const response = await fetch(`https://discord.com/api/v10/guilds/${DISCORD_SERVER_ID}`, {
        headers: {
            Authorization: `Bot ${DISCORD_BOT_TOKEN}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching server info: ${response.statusText}`);
    }

    return response.json() as Promise<DiscordServerInfo>;
}

export async function fetchDiscordChannels(): Promise<DiscordChannel[]> {
    const response = await fetch(`https://discord.com/api/v10/guilds/${DISCORD_SERVER_ID}/channels`, {
        headers: {
            Authorization: `Bot ${DISCORD_BOT_TOKEN}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching channels: ${response.statusText}`);
    }

    return response.json() as Promise<DiscordChannel[]>;
}

export async function fetchDiscordMembers(): Promise<DiscordMember[]> {
    const response = await fetch(`https://discord.com/api/v10/guilds/${DISCORD_SERVER_ID}/members?limit=1000`, {
        headers: {
            Authorization: `Bot ${DISCORD_BOT_TOKEN}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching members: ${response.statusText}`);
    }

    return response.json() as Promise<DiscordMember[]>;
}

export async function fetchDiscordRoles(): Promise<DiscordRole[]> {
    const response = await fetch(`https://discord.com/api/v10/guilds/${DISCORD_SERVER_ID}/roles`, {
        headers: {
            Authorization: `Bot ${DISCORD_BOT_TOKEN}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching roles: ${response.statusText}`);
    }

    return response.json() as Promise<DiscordRole[]>;
}

export async function fetchDiscordEmojis(): Promise<DiscordEmoji[]> {
    const response = await fetch(`https://discord.com/api/v10/guilds/${DISCORD_SERVER_ID}/emojis`, {
        headers: {
            Authorization: `Bot ${DISCORD_BOT_TOKEN}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching emojis: ${response.statusText}`);
    }

    return response.json() as Promise<DiscordEmoji[]>;
}

export async function fetchDiscordChannelByName(channelName: string): Promise<DiscordChannel> {
    const channels = await fetchDiscordChannels();
    const channel = channels.find(c => c.name === channelName);

    if (!channel) {
        throw new Error(`Channel with name ${channelName} not found`);
    }

    return channel;
}

export async function fetchDiscordMemberByName(memberName: string): Promise<DiscordMember> {
    const members = await fetchDiscordMembers();
    const member = members.find(m => m.user.username === memberName);

    if (!member) {
        throw new Error(`Member with name ${memberName} not found`);
    }

    return member;
}

export async function fetchDiscordRoleByName(roleName: string): Promise<DiscordRole> {
    const roles = await fetchDiscordRoles();
    const role = roles.find(r => r.name === roleName);

    if (!role) {
        throw new Error(`Role with name ${roleName} not found`);
    }

    return role;
}

export async function fetchDiscordEmojiByName(emojiName: string): Promise<DiscordEmoji> {
    const emojis = await fetchDiscordEmojis();
    const emoji = emojis.find(e => e.name === emojiName);

    if (!emoji) {
        throw new Error(`Emoji with name ${emojiName} not found`);
    }

    return emoji;
}

// Fonction Roles formater
const orderOfImportance = ['Fondateur', 'Staff', 'Formateur'];
const blacklist = ['Membre', 'Server Booster', 'Ping vocal', 'Ping annonce', 'Non vérifié', 'Ping gaming', 'membre pour bot'];

export const getRole = (roles: string[]) => {
    if (!roles) {
      return 'student';
    }
  
    if (roles.some(role => role.startsWith('Formateur'))) {
        return 'teacher';
    }
    
    if (roles.includes('Staff')) {
      return 'admin';
    }

    return 'student';
};
export const orderRole = (roles: string[]) => {
    if (!roles || roles.length === 0) {
      return 'Etudiant';
    }
  
    // Filter out blacklisted roles
    const filteredRoles = roles.filter(role => !blacklist.includes(role));
  
    // Sort roles by order of importance
    const sortedRoles = filteredRoles.sort((a, b) => {
      const indexA = orderOfImportance.findIndex(impRole => a.startsWith(impRole));
      const indexB = orderOfImportance.findIndex(impRole => b.startsWith(impRole));
  
      if (indexA === -1 && indexB === -1) {
        return 0; // Neither role is in the orderOfImportance
      }
      if (indexA === -1) {
        return 1; // 'a' is less important
      }
      if (indexB === -1) {
        return -1; // 'b' is less important
      }
      return indexA - indexB;
    });
  
    // Construct the role text
    const roleText = sortedRoles.slice(0, 2).join(' • ')+ (sortedRoles.length-2 > 2 ? ' • +'+sortedRoles.length : '');
  
    return roleText || 'Etudiant';
};