'use client';
import { useEffect, useState } from 'react';
import CardEvent from './CardEvent';
import CardMedium from './CardMedium';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

export interface Section {
  title: string;
  description: string;
  buttonText: string;
  image: string;
}
export interface Event {
  name: string;
  description: string;
  occurrences: { formattedDate: string }[];
  creator: {
    global_name: string;
    id: string;
    avatar: string;
    role: string;
  };
  channel_name: string;
}

export interface Tweet {
  id_str: string;
  text: string;
  media: { media_url_https: string }[];
  user: {
    name: string;
    screen_name: string;
    profile_image_url_https: string;
  };
}

export interface MediumPost {
  title: string;
  content: string;
  link: string;
  image: string;
}

const LastNews = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [tweets, setTweets] = useState<Tweet[]>([]);
  const [mediumPosts, setMediumPosts] = useState<MediumPost[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('/api/discord/scheduled');
        const data = await response.json();
        if (response.ok && data) {
          setEvents(data);
        } else {
          throw new Error('Failed to fetch events');
        }
      } catch (error) {
        console.error(error);
        setEvents([]); // Ensure state is updated even on error
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchMediumPosts = async () => {
      try {
        const response = await fetch('/api/medium');
        const data = await response.json();
        if (response.ok && data) {
          setMediumPosts(data);
        } else {
          throw new Error('Failed to fetch medium posts');
        }
      } catch (error) {
        console.error(error);
        setMediumPosts([]); // Ensure state is updated even on error
      }
    };
    fetchMediumPosts();
  }, []);

  const mergedData = () => {
    const merged: (Event | Tweet | MediumPost)[] = [];
    const maxLength = Math.max(
      events.length,
      tweets.length,
      mediumPosts.length
    );

    for (let i = 0; i < maxLength; i++) {
      if (i < events.length) {
        merged.push(events[i]);
      }
      if (i < tweets.length) {
        merged.push(tweets[i]);
      }
      if (i < mediumPosts.length) {
        merged.push(mediumPosts[i]);
      }
    }

    return merged;
  };

  return (
    <div id="actualites" className="py-10 text-center md:text-start">
      <div className="container flex flex-col items-center md:items-start">
        <h2 className="text-primary/80 text-xl font-semibold">
          À NE PAS MANQUER
        </h2>
        <h3 className="text-primary text-3xl font-bold">
          Les dernières nouveautés
        </h3>
        <div className="bg-banner sm:w-[290px] rounded-full w-full h-3 my-2 mb-8 mx-4" />
      </div>
      <div className="relative">
        <div className="hidden md:block absolute top-28 bg-banner w-full h-24 -z-10" />
        <ScrollArea className="container w-full whitespace-nowrap">
          <div className="flex w-max space-x-4 py-4">
            {mergedData().map((item, index) => (
              <div key={index}>
                {(item as Event).name ? (
                  <CardEvent event={item as Event} index={index} />
                ) : (
                  <CardMedium post={item as MediumPost} index={index} />
                )}
              </div>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </div>
  );
};

export default LastNews;
