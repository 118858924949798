'use client';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { slideInFromRight } from '@/utils/motion';
import { Badge } from '@/components/ui/badge';
import Image from 'next/image';
import dynamic from 'next/dynamic';

const RoadSVG = dynamic(() => import('./RoadSVG'), {
  ssr: false,
  loading: () => <LoadRoad />,
});

const RoadMobileSVG = dynamic(() => import('./RoadMobileSVG'), {
  ssr: false,
  loading: () => <LoadRoad />,
});

//**
// Structure de données pour les textes
//**
export type StepType = {
  id: number;
  distance: number;
  title: string;
  desc: string;
};

const dataSteps: StepType[] = [
  {
    id: 1,
    distance: 70,
    title: 'S1.0 - Lancement de la Plateforme',
    desc: '{badge}Application PWA*{/badge} \n {b}Pour les Élèves:{/b} Accès à un catalogue de cours diversifié, inscription facile, suivi de progression, tests interactifs. \n {b}Pour les Enseignants:{/b} Outils de création et gestion de cours, analyses de performance, communication directe avec les élèves. \n {small}*PWA (Progressive Web App) : une application web qui fonctionne comme une application mobile.{/small}',
  },
  {
    id: 2,
    distance: 100,
    title: "S1.1 - Optimisation de l'Expérience Utilisateur",
    desc: "{badge}UI/UX{/badge} {badge}Analyse de Données{/badge} \n {b}Pour les Élèves:{/b} Recommandations de cours personnalisées. \n {b}Pour les Enseignants:{/b} Simplification de la création de cours, intégration de ressources variées. \n {b}Administration:{/b} Analyse approfondie des données de la plateforme pour améliorer l'expérience utilisateur.",
  },
  {
    id: 3,
    distance: 0,
    title: 'S1.2 - Engagement et Fidélisation',
    desc: '{badge}Gamification{/badge} {badge}Communauté{/badge} \n {b}Défis et Compétitions:{/b} Classements et récompenses pour les meilleurs élèves. \n {b}Événements Spéciaux:{/b} Promotions et événements saisonniers comme Noël et Halloween avec coupons et codes promotionnels.',
  },
  {
    id: 4,
    distance: 0,
    title: 'S1.3 - Support et Assistance Améliorés',
    desc: '{badge}Support{/badge} {badge}AI{/badge} \n {b}FAQ et Assistance AI:{/b} Réponses rapides et automatiques aux questions courantes. \n {b}Mentorat:{/b} Sessions de mentorat personnalisées pour aider les élèves à réussir.',
  },
  {
    id: 5,
    distance: 105,
    title: 'S2.0 - Marketplace de Cours',
    desc: '{badge}Marketplace{/badge} {badge}Commerce{/badge} \n {b}Pour les Enseignants:{/b} Possibilité de monétiser leurs cours, outils de marketing. \n {b}Pour les Élèves:{/b} Accès à un large éventail de cours premium.',
  },
  {
    id: 6,
    distance: 100,
    title: 'S2.1 - Plateforme de Recrutement et de Carrière',
    desc: "{badge}Recrutement{/badge} {badge}Carrière{/badge} \n {b}CV et Portfolio en Ligne:{/b} Mise en valeur des compétences des utilisateurs. \n {b}Matching Automatique:{/b} Algorithme pour trouver l'emploi idéal. \n {b}Portail de Carrière:{/b} Accès à des opportunités d'emploi et de stages/alternances.",
  },
  {
    id: 7,
    distance: 120,
    title: 'S2.2 - Expansion Internationale',
    desc: "{badge}International{/badge} {badge}Multilingue{/badge} \n {b}Support Multilingue:{/b} Plateforme disponible en plusieurs langues. \n {b}Partenariats Globaux:{/b} Collaboration avec des institutions éducatives à l'échelle mondiale.",
  },
  {
    id: 8,
    distance: 140,
    title: 'S2.3 - Personnalisation Avancée avec IA',
    desc: "{badge}Intelligence Artificielle{/badge} {badge}Personnalisation{/badge} \n {b}Cours Personnalisés:{/b} Recommandations et contenus adaptés grâce à l'IA. \n {b}Chatbots Intelligents:{/b} Assistance 24/7 avec des chatbots alimentés par l'IA.",
  },
  {
    id: 9,
    distance: 160,
    title: 'S3.0 - Apprentissage en Réalité Virtuelle et Augmentée',
    desc: '{badge}VR{/badge} {badge}AR{/badge} \n {b}Cours en VR:{/b} Expériences immersives pour un apprentissage interactif et engageant. \n {b}Pratiques en AR:{/b} Utilisation de la réalité augmentée pour des démonstrations pratiques et des exercices interactifs.',
  },
];

const RoadMap = () => {
  const { ref, inView } = useInView();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 851) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={slideInFromRight()}
      className="relative container flex flex-col items-center justify-center mt-24"
    >
      <h2 className="text-3xl font-bold text-primary">Roadmap</h2>
      <div className="bg-banner min-w-[140px] max-w-[280px] rounded-full h-3 my-2 mb-8" />

      <div className="relative flex items-start justify-center md:w-2/3 mx-auto">
        <p className="text-justify text-primary max-w-[565px]">
          Notre feuille de route illustre l&apos;évolution passionnante de notre
          application d&apos;apprentissage. Chaque étape représente une
          amélioration significative, fruit de notre engagement constant à
          innover et à répondre aux besoins de nos utilisateurs.
        </p>
      </div>
      <motion.div
        className="relative w-[100vw] md:w-full"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      >
        <div className="relative z-1 px-20 pt-24 md:pt-0 overflow-y-auto md:overflow-hidden md:px-0 md:mx-52">
          {isMobile ? (
            <RoadMobileSVG datasteps={dataSteps} />
          ) : (
            <RoadSVG datasteps={dataSteps} />
          )}
        </div>
        <div className="absolute right-0 bottom-[-87px] md:bottom-[-80px] z-[-1]">
          <Image
            className="pointer-events-none w-[480px] h-[300px] sm:w-[580px] sm:h-[420px] md:w-[640px] md:h-[435px]"
            src={'/img/header/header_bot.webp'}
            alt="Robot"
            width={600}
            height={408}
            priority
            draggable={false}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default RoadMap;

export const RenderText = ({ lines }: { lines: string[] }) => {
  const [displayLines, setDisplayLines] = React.useState(lines);

  const formatText = (text: string) => {
    const parts = text.split(
      /(\{b\}|\{\/b\}|\{i\}|\{\/i\}|\{badge\}|\{\/badge\}|\{small\}|\{\/small\})/
    );
    let output = [];

    for (let i = 0; i < parts.length; i++) {
      switch (parts[i]) {
        case '{b}':
          if (i + 2 < parts.length && parts[i + 2] === '{/b}') {
            output.push(<strong key={i}>{parts[i + 1]}</strong>);
            i += 2; // Skip over the content and closing tag
          }
          break;
        case '{i}':
          if (i + 2 < parts.length && parts[i + 2] === '{/i}') {
            output.push(<em key={i}>{parts[i + 1]}</em>);
            i += 2; // Skip over the content and closing tag
          }
          break;
        case '{badge}':
          if (i + 2 < parts.length && parts[i + 2] === '{/badge}') {
            output.push(
              <Badge className="mr-2" key={i}>
                {parts[i + 1]}
              </Badge>
            );
            i += 2; // Skip over the content and closing tag
          }
          break;
        case '{small}':
          if (i + 2 < parts.length && parts[i + 2] === '{/small}') {
            output.push(
              <small className="block text-xs leading-3 pt-4" key={i}>
                {parts[i + 1]}
              </small>
            );
            i += 2; // Skip over the content and closing tag
          }
          break;
        default:
          if (parts[i].trim() !== '') {
            output.push(<span key={i}>{parts[i]}</span>);
          }
          break;
      }
    }
    return output;
  };

  return (
    <>
      {displayLines.map((line, index) => (
        <React.Fragment key={index}>
          {formatText(line)}
          {index < displayLines.length - 1 ? <br /> : null}
        </React.Fragment>
      ))}
    </>
  );
};

const LoadRoad: React.FC = () => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={'100%'}
    height={'100%'}
    viewBox="0 0 2220 1060"
    fill="none"
  >
    <motion.path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2209.34 109.732C2209.6 109.951 2209.9 110.097 2210.26 110.084C2211.03 110.097 2211.64 109.489 2211.64 108.736C2211.66 108.214 2205.29 105.432 2193.52 102.785C2164.72 96.2621 2103.14 84.0673 2037.17 73.573C1973.78 63.4917 1906.52 55.0986 1860.32 54.2363C1771.26 52.7787 1681.53 58.1352 1595.38 77.2047C1507.26 96.6751 1423.25 130.757 1347.18 184.407C1286.11 228.121 1234.48 281.152 1188.68 339.429C1148.41 390.613 1112.53 445.708 1078.33 501.532C1055.83 538.201 1034.32 575.344 1012.23 611.601C957.691 703.22 897.373 774.991 831.953 830.293C755.7 894.802 672.607 936.852 582.726 960.889C412.059 1006.56 218.498 989.129 6.6073 938.528C4.11734 937.86 1.54234 939.342 0.8743 941.844C0.206262 944.334 1.70024 946.884 4.17807 947.577C218.109 1011.82 415.667 1040.79 592.819 1002.9C709.228 978.027 816.819 924.426 914.232 834.265C968.89 783.664 1019.87 721.099 1067.71 645.72C1090.15 609.597 1112.46 572.83 1134.84 536.015C1167.67 482.025 1201.19 428.205 1238.79 377.835C1279.84 322.862 1325.34 271.641 1380.48 229.263C1448.58 176.135 1525.19 141.652 1605.86 119.085C1688.19 96.0799 1774.5 85.1605 1860.72 81.2615C1897.66 79.5003 1948.61 81.9175 2000.24 85.865C2076.25 91.6952 2153.39 100.866 2189.3 106.21C2198.47 107.558 2204.91 108.639 2207.95 109.356C2208.55 109.489 2209.15 109.672 2209.34 109.732Z"
      fill="#5F0F90"
    />
  </motion.svg>
);
