'use client';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
  slideInFromTop,
} from '@/utils/motion';
import React from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';
import Link from 'next/link';
import Cardreview from './CardReviews';
import { CardType } from '@/types/CardTypes';

const Reviews = () => {
  const { ref, inView } = useInView();
  const plugin = React.useRef(
    Autoplay({ delay: 2500, stopOnInteraction: true })
  );

  const reviews: CardType[] = [
    {
      id: 1,
      title:
        'Expérience Exceptionnelle',
      descriptions:
        "Skillscal m'a vraiment aidé pour mon développement dans différents projets grâce à la communauté active qui s'entraide en répondant aux questions des uns et des autres. \n\nCette aide permet de gagner beaucoup de temps quand on développe. Elle m'a aussi permis de continuer à collaborer avec certaines personnes sur le long terme.",
      rating: 5,
      avatar: '/api/proxy?url=https://cdn.discordapp.com/avatars/317609115864137731/83fe53086afd51c7b1b1ce1d229b6483.webp?size=128',
      author: 'ZewweK',
      role: 'Elève IA, Elève Immobilier, Elève Code, Elève Bourse, Elève Crypto',
    },
    {
      id: 2,
      title: "Service de Qualité",
      descriptions:
        "Skillscal m'a vraiment aidé au développement de nouvelles compétences et à leur consolidation dans des domaines novateurs, grâce à la communauté active qui s'entraide en répondant aux questions des uns et des autres avec énormément de bienveillance et de pertinence. \n\nCette aide est essentielle afin de gagner énormément de temps quand on développe ses compétences dans de nouveaux secteurs porteurs. \n\nSkillscal m'a aussi permis de rencontrer de nouveaux contacts pour démarrer et continuer sur le long terme des collaborations avec certaines personnes sur divers projets.",
      rating: 4,
      avatar: '/api/proxy?url=https://cdn.discordapp.com/avatars/1142237916798648320/86236151a420a143a7fdc660d8342da3.webp?size=128',
      author: 'Nomade',
      role: 'Elève Réseaux, Elève Crypto',
    },
    {
      id: 3,
      title: 'Belle Découverte',
      descriptions:
      "Skillscal m'a bien aidé pour mon développement personnel autour de mes compétences, dorénavant j'ai pu fonder mon propre projet suite à mon expérience chez Skillscal. \n\nJ'ai rencontré des personnes incroyables dans cette communauté sur tous les domaines et ça m'a permis d'en ressortir plus grand !",
      rating: 5,
      avatar: '/api/proxy?url=https://cdn.discordapp.com/avatars/668487185950834699/8f3643ff291772a10c7f6bc4e6cad831.webp?size=128',
      author: 'TimOnSol',
      role: 'Elève Réseaux, Elève Crypto',
    },
    {
      id: 4,
      title: 'Une Communauté Inestimable',
      descriptions:
        "Rejoindre Skillscal a été une décision formidable pour moi. \nLa communauté est incroyablement réactive et toujours prête à aider. J'ai pu améliorer mes compétences techniques grâce aux nombreux tutoriels et aux sessions de mentorat. \n\nDe plus, les échanges avec d'autres membres m'ont permis de découvrir de nouvelles perspectives et de nouvelles idées pour mes projets. \n\nC'est un endroit où l'on se sent vraiment soutenu et encouragé.",
      rating: 4,
      avatar: '/api/proxy?url=https://cdn.discordapp.com/avatars/1076477743203618886/5801f8b3811fc42d5bd26bc9f1d64559.webp?size=128',
      author: 'Alex Innov',
      role: 'Elève Réseaux, Skiller Immobilier, Elève Code, Skiller Bourse, Elève Crypto',
    },
    {
      id: 5,
      title: "Service Satisfaisant",
      descriptions:
        "SkillScal, c'est un serveur intéressant pour en apprendre sur tout ce qui touche à l'argent en général. L'éducation financière et plein d'autres domaines sont abordés. \n\nUn endroit d'échange pour le bon et moins bon. Un bon serveur pour ce qui est de faire des rencontres. \n\nBeaucoup de gens déterminés. Des vocales éducatives.",
      rating: 4,
      avatar: '/api/proxy?url=https://cdn.discordapp.com/avatars/912084080114425876/922a38f2fa3d07f58a3ce244dc2150ae.webp?size=128',
      author: "Milhouse",
      role: 'Elève Réseaux, Skiller Immobilier, Elève Code, Skiller Bourse, Elève Crypto',
    },
    // {
    //   id: 6,
    //   title: 'Un réseau professionnel enrichissant',
    //   descriptions:
    //     "SkillScale ne m'a pas seulement apporté des connaissances précieuses, mais m'a aussi permis de rejoindre un réseau professionnel dynamique et enrichissant. Les interactions avec les autres membres sont très bénéfiques.",
    //   rating: 5,
    //   author: 'Sophie',
    //   role: 'Consultante en stratégie',
    // },
  ];

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={slideInFromLeft()}
      id="review"
      className="container md:mx-auto px-0 md:px-18 2xl:px-0 pt-10 md:pt-20 md:pb-10"
    >
      <Carousel
        plugins={[plugin.current]}
        className="size-full"
        onMouseEnter={plugin.current.stop}
        onMouseLeave={plugin.current.reset}
      >
        <CarouselContent
          className="gap-8 md:gap-12 md:pl-[2rem]
        "
        >
          {reviews.map((review: CardType, index: number) => (
            <CarouselItem
              key={index}
              className={`basis-[100%] md:basis-[30.5%] my-20 hover:scale-110 md:hover:scale-115 hover:z-10 transition-transform duration-300 ease-in-out pr-[1rem] md:pr-[2rem]`}
            >
              {/* <Link href={`#`}> */}
                <Cardreview text={review} />
              {/* </Link> */}
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className='absolute left-4 md:-left-16 md:top-1/2 md:-translate-y-1/2'/>
        <CarouselNext className='absolute right-2 md:-right-16 md:top-1/2 md:-translate-y-1/2' />
      </Carousel>
    </motion.div>
  );
};
export default Reviews;
